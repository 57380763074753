import React from "react";
const PortfolioItem = ({ portfolio }) => {
  return (
    <div className="rwt-card serviceCard">
      <div className="inner ">
        <div className="thumbnail">
          <figure className="card-image">
            <h5 className="title2 mb--10">
              {/* <Link to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`}> */}
              {/* {portfolio.title} */}
              {/* </Link> */}
            </h5>
            {/* <Link to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`}> */}
            <img
              className="img-fluid filterImage servicesImg"
              src={portfolio.portfolioImage}
              alt="Portfolio-01"
            />
            {/* </Link> */}
          </figure>
          {/* <Link to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`} className="rwt-overlay"></Link> */}
        </div>
        <div className="content">
          <h5 className="title mb--10 center">
            {/* <Link to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`}> */}
            {portfolio.title}
            {/* </Link> */}
          </h5>

          <h6 className="subtitle b2 text-capitalize center">
            {portfolio.category
              .toLowerCase()
              .includes("it infrastructure services")
              ? "IT Infrastructure Services"
              : portfolio.category}
          </h6>
        </div>
      </div>
    </div>
  );
};
export default PortfolioItem;
