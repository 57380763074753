import React from "react";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import HeaderTwo from "../../common/header/HeaderTwo";
import Copyright from "../../common/footer/Copyright";
import FooterFour from "../../common/footer/FooterFour";
import {
  cyber_security,
  networking,
  data_center,
  cyber_security_images,
  networking_images,
  data_center_images,
} from "../../assets/Lists/SolutionsList";
import ReactGA from "react-ga";
import { useEffect } from "react";

const Split = () => {
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    console.log(savedTheme, "split");
  }, [localStorage.getItem("theme")]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });
  return (
    <>
      <SEO title="Solutions" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    Our Solutions
                    <br />
                  </h1>
                  <p className="description">
                    Explore comprehensive solutions tailored to meet your unique
                    challenges, as we strive to transform problems into
                    opportunities through innovative and effective strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap-inside">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="CYBER SECURITY"
                  description=""
                />
              </div>
            </div>
            <SlpitOne
              img="./images/Solutions/cybersplit.jpg"
              list={cyber_security}
              images={cyber_security_images}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="DATA CENTER & CLOUD"
                  description=""
                />
              </div>
            </div>
            <SlipFour
              img="./images/Solutions/datacentersplit.jpg"
              list={data_center}
              images={data_center_images}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="NETWORKING & MONITORING"
                  description=""
                />
              </div>
            </div>
            <SlpitOne
              img="./images/Solutions/networkingsplit.jpg"
              list={networking}
              images={networking_images}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterFour />
        <Copyright />
      </main>
    </>
  );
};
export default Split;
