import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
const Nav = ({
  aboutRef,
  servicesRef,
  solutionRef,
  handleRef,
  homeRef,
  onClose,
}) => {
  const [activeLink, setActiveLink] = useState("home");
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (
        aboutRef?.current.offsetTop - 100 <= scrollPosition &&
        scrollPosition < servicesRef.current.offsetTop - 100
      ) {
        setActiveLink("about");
      } else if (
        servicesRef?.current.offsetTop - 100 <= scrollPosition &&
        scrollPosition < solutionRef.current.offsetTop - 100
      ) {
        setActiveLink("services");
      } else if (solutionRef?.current.offsetTop - 100 <= scrollPosition - 100) {
        setActiveLink("solutions");
      } else {
        setActiveLink("home");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [aboutRef, servicesRef, solutionRef]);

  const handleClick = (ref, link) => {
    setActiveLink(link);
    handleRef(ref);
    isMobile && onClose()
  };

  return (
    <ul className="mainmenu">
      <li>
        {location.pathname === "/" ? (
          <a
            className={activeLink === "home" ? "active" : ""}
            onClick={() => handleClick(homeRef, "home")}
          >
            Home
          </a>
        ) : (
          <a href="/">Home</a>
        )}
      </li>
      <li>
        {location.pathname === "/" ? (
          <a
            className={activeLink === "about" ? "active" : ""}
            onClick={() => handleClick(aboutRef, "about")}
          >
            About
          </a>
        ) : (
          <a
            href="/about-us"
            className={location.pathname === "/about-us" ? "activeInside" : ""}
          >
            About
          </a>
        )}
      </li>
      <li>
        {location.pathname === "/" ? (
          <a
            className={activeLink === "services" ? "active" : ""}
            onClick={() => handleClick(servicesRef, "services")}
          >
            Services
          </a>
        ) : (
          <a
            href="/services"
            className={location.pathname === "/services" ? "activeInside" : ""}
          >
            Services
          </a>
        )}
      </li>
      <li>
        {location.pathname === "/" ? (
          <a
            className={activeLink === "solutions" ? "active" : ""}
            onClick={() => handleClick(solutionRef, "solutions")}
          >
            Solutions
          </a>
        ) : (
          <a
            href="/solutions"
            className={location.pathname === "/solutions" ? "activeInside" : ""}
          >
            Solutions
          </a>
        )}
      </li>
    </ul>
  );
};
Nav.propTypes = {
  aboutRef: PropTypes.object,
  servicesRef: PropTypes.object,
  solutionRef: PropTypes.object,
  handleRef: PropTypes.func, // Check this line
  homeRef: PropTypes.object,
};
export default Nav;
