export const clients_images = [
  {
    img: "./images/OurClients/1.png",
  },
  {
    img: "./images/OurClients/2.png",
  },
  {
    img: "./images/OurClients/3.png",
  },
  {
    img: "./images/OurClients/4.png",
  },
  {
    img: "./images/OurClients/11.png",
  },
  {
    img: "./images/OurClients/5.png",
  },
  {
    img: "./images/OurClients/6.png",
  },
  {
    img: "./images/OurClients/7.png",
  },
  {
    img: "./images/OurClients/8.png",
  },
  {
    img: "./images/OurClients/16.png",
  },
  {
    img: "./images/OurClients/23.png",
  },
  {
    img: "./images/OurClients/25.png",
  },
  {
    img: "./images/OurClients/19.png",
  },
  {
    img: "./images/OurClients/10.png",
  },

  {
    img: "./images/OurClients/12.png",
  },
  {
    img: "./images/OurClients/13.png",
  },
  {
    img: "./images/OurClients/14.png",
  },
  {
    img: "./images/OurClients/15.png",
  },

  {
    img: "./images/OurClients/17.png",
  },
  {
    img: "./images/OurClients/18.png",
  },
  {
    img: "./images/OurClients/19.png",
  },
  {
    img: "./images/OurClients/20.png",
  },
  {
    img: "./images/OurClients/21.png",
  },
  {
    img: "./images/OurClients/22.png",
  },
  {
    img: "./images/OurClients/24.png",
  },

  {
    img: "./images/OurClients/26.png",
  },
  {
    img: "./images/OurClients/27.png",
  },
  {
    img: "./images/OurClients/28.png",
  },
  {
    img: "./images/OurClients/29.png",
  },
  {
    img: "./images/OurClients/1.png",
  },
  {
    img: "./images/OurClients/2.png",
  },
  {
    img: "./images/OurClients/3.png",
  },
  {
    img: "./images/OurClients/4.png",
  },
  {
    img: "./images/OurClients/11.png",
  },
  {
    img: "./images/OurClients/5.png",
  },
  {
    img: "./images/OurClients/6.png",
  },
  {
    img: "./images/OurClients/7.png",
  },
  {
    img: "./images/OurClients/8.png",
  },
  {
    img: "./images/OurClients/16.png",
  },
  {
    img: "./images/OurClients/23.png",
  },
  {
    img: "./images/OurClients/25.png",
  },
  {
    img: "./images/OurClients/19.png",
  },
  {
    img: "./images/OurClients/10.png",
  },

  {
    img: "./images/OurClients/12.png",
  },
  {
    img: "./images/OurClients/13.png",
  },
  {
    img: "./images/OurClients/14.png",
  },
  {
    img: "./images/OurClients/15.png",
  },

  {
    img: "./images/OurClients/17.png",
  },
  {
    img: "./images/OurClients/18.png",
  },
  {
    img: "./images/OurClients/19.png",
  },
  {
    img: "./images/OurClients/20.png",
  },
  {
    img: "./images/OurClients/21.png",
  },
  {
    img: "./images/OurClients/22.png",
  },

  {
    img: "./images/OurClients/24.png",
  },
  {
    img: "./images/OurClients/26.png",
  },
  {
    img: "./images/OurClients/27.png",
  },
  {
    img: "./images/OurClients/28.png",
  },
  {
    img: "./images/OurClients/29.png",
  },
];
