import React from "react";
import Slider from "react-slick";
import { useState,useEffect } from "react";
const SliderLogo = ({ images }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile?2:6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    draggable: false,
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        {images?.map((item, index) => (
          <div key={index}>
            <img
              src={item?.img}
              alt={`slide-${index}`}
              className="slider_img"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderLogo;
