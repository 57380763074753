import React, { useRef, forwardRef, useEffect } from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceOne from "../elements/service/ServiceOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ServiceFour from "../elements/service/ServiceFour";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import { ServiceList, SolutionsList } from "../assets/Lists/ServicesList";
import { clients_images } from "../assets/Lists/ClientsList";
import SliderLogo from "../elements/slider/SliderLogo";
import FooterFour from "../common/footer/FooterFour";
import ReactGA from "react-ga";

const BusinessConsulting2 = () => {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const solutionRef = useRef(null);
  const homeRef = useRef(null);
  const handleRef = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
  })
  return (
    <>
      <SEO title="EchoServe" />
      <main ref={homeRef} className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo
          btnStyle="round"
          HeaderSTyle="header-not-transparent"
          aboutRef={aboutRef}
          servicesRef={servicesRef}
          solutionRef={solutionRef}
          homeRef={homeRef}
          handleRef={handleRef}
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    Committed To Your <br />{" "}
                    <Typed
                      strings={[
                        "Success.",
                        "Cyber Security.",
                        "IT Resilience.",
                      ]}
                      typeSpeed={80}
                      backSpeed={4}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    EchoServe is a system integrator and a solution advisor for
                    IT infrastructure , networking, and cyber security.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/contact"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <div ref={aboutRef} id="about"></div>
        <AboutOne description="Welcome to EchoServe, where security meets innovation. We provide top-notch IT solutions tailored to safeguard your business from threats while optimizing performance. Partner with us for a secure and successful future." />
        {/* End About Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div ref={servicesRef} className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Services provided for you."
                  description="Get the precise IT services you’re looking for, customized to your specific needs."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
              ServiceList={ServiceList}
              callToAction="#"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Solutions Area  */}
        <div ref={solutionRef} className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Solutions provided for you."
                  description="Focus your time and energy on your core business operations and goals, while we provide all-inclusive IT solutions."
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
              SolutionsList={SolutionsList}
              callToAction="#"
            />
          </div>
        </div>
        {/* End Solutions Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start ourClient Area   */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Our Clients."
                  description="Our clients consistently praise our commitment to excellence, as we strive to continually surpass their expectations and deliver outstanding results.
                  "
                />
              </div>
            </div>
            <SliderLogo images={clients_images} />
          </div>
        </div>

        {/* Start ourClient Area  */}

        <Separator />
        {/* Start Team Area  */}
        {/*  <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts."
                                        title = "Our Company Experts."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                    </div>
                </div>
                 End Team Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        {/*    <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
              End Testimonial Area  */}

        <FooterFour />
        <Copyright />
      </main>
    </>
  );
};

export default forwardRef(BusinessConsulting2);
