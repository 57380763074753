import React from "react";
import SEO from "../../common/SEO";
import ContactOne from "./ContactOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterFour from "../../common/footer/FooterFour";
import Copyright from "../../common/footer/Copyright";
import { useEffect } from "react";
import ReactGA from "react-ga";

const Contact = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });
  return (
    <>
      <SEO title="Contact " />
      <HeaderTwo
        btnStyle="round"
        HeaderSTyle="header-not-transparent"
      ></HeaderTwo>
      {/* Start Slider Area  */}
      <div className="slider-area slider-style-1 bg-transparent height-850">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h1 className="title theme-gradient display-two">
                  Contact Us
                  <br />
                </h1>
                <p className="description">
                  Your feedback is invaluable to us; feel free to reach out with
                  any questions, suggestions, or concerns, and our dedicated
                  team will promptly assist you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area  */}
      <div className="main-content">
        {/* Start Contact Area  */}
        <div className="rwt-contact-area rn-section-gap-inside">
          <div className="container">
            <ContactOne />
          </div>
        </div>
        {/* End Contact Area  */}
      </div>
      <FooterFour />
      <Copyright />
    </>
  );
};
export default Contact;
