import React from "react";

const BrandList = [
  {
    image: "./images/certification/1.png",
  },
  {
    image: "./images/certification/2.png",
  },
];
const BrandList2 = [
  {
    image: "./images/certification/3.png",
  },
  {
    image: "./images/certification/4.png",
  },
  {
    image: "./images/certification/5.png",
  },
];
const BrandList3 = [
  {
    image: "./images/certification/6.png",
  },
  {
    image: "./images/certification/7.png",
  },
  {
    image: "./images/certification/8.png",
  },
];

const BrandThree = ({ brandStyle }) => {
  return (
    <div>
      <ul className={`brand-list ${brandStyle}`}>
        {BrandList.map((data, index) => (
          <li key={index}>
            <a href="#">
              <img src={`${data.image}`} alt="Brand Image" />
            </a>
          </li>
        ))}
      </ul>
      <ul className={`brand-list ${brandStyle}`}>
        {BrandList2.map((data, index) => (
          <li key={index}>
            <a href="#">
              <img src={`${data.image}`} alt="Brand Image" />
            </a>
          </li>
        ))}
      </ul>
      <ul className={`brand-list ${brandStyle}`}>
        {BrandList3.map((data, index) => (
          <li key={index}>
            <a href="#">
              <img src={`${data.image}`} alt="Brand Image" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BrandThree;
