export const cyber_security = [
  {
    point: "Network Firewalls",
  },
  {
    point: "Vulnerability Management",
  },
  {
    point: "Security Rating and Risk Management",
  },
  {
    point: "Attack Surface Management and Threat Detection",
  },
  {
    point: "DNS Security",
  },
  {
    point: "Email Security and ATP",
  },
  {
    point: "Network Detection and Response",
  },
  {
    point: "Endpoint Security and XDR",
  },
  {
    point: "SIEM",
  },
  {
    point: "Cyber Security Analytics",
  },
];

export const data_center = [
  {
    point: "Software-defined data center",
  },
  {
    point: "Hyper-converged infrastructure",
  },
  {
    point: "Enterprise servers and storage",
  },
  {
    point: "Backup and replication",
  },
  {
    point: "High availability & disaster recovery",
  },
  {
    point: "Desktop and application virtualization and delivery",
  },
];

export const networking = [
  {
    point: "Application delivery controller",
  },
  {
    point: "Network automation",
  },
  {
    point: "WAN optimization",
  },
  {
    point: "Bandwidth management​",
  },
  {
    point: "Wireless solutions​",
  },
  {
    point: "Wireless solutions​",
  },
];

export const cyber_security_images = [
  {
    img: "./images/Solutions/1/1-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/2-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/3-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/4-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/5-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/6-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/7-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/8-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/9-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/10-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/11-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/12-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/13-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/15-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/16-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/17-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/19-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/21-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/1-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/2-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/3-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/4-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/5-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/6-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/7-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/8-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/9-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/10-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/11-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/12-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/13-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/15-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/16-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/17-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/19-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/21-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/1-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/2-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/3-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/4-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/5-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/6-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/7-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/8-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/9-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/10-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/11-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/12-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/13-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/15-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/16-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/17-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/19-removebg-preview.png",
  },
  {
    img: "./images/Solutions/1/21-removebg-preview.png",
  },
];

export const data_center_images = [
  {
    img: "./images/Solutions/2/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/26-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/27-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/28-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/26-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/27-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/28-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/26-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/27-removebg-preview.png",
  },
  {
    img: "./images/Solutions/2/28-removebg-preview.png",
  },
];
export const networking_images = [
  {
    img: "./images/Solutions/3/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/22-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/29-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/22-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/29-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/22-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/29-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/14-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/22-removebg-preview.png",
  },
  {
    img: "./images/Solutions/3/29-removebg-preview.png",
  },
];
