import React from "react";
import SEO from "../common/SEO";
import FooterFour from "../common/footer/FooterFour";
import AboutTwo from "../elements/about/AboutTwo";
import Separator from "../elements/separator/Separator";
import HeaderTwo from "../common/header/HeaderTwo";
import Copyright from "../common/footer/Copyright";
const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    About Us <br />
                  </h1>
                  <p className="description">
                    At EchoServe, our journey is defined by a shared
                    passion for excellence. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        <AboutTwo image="./images/about/contact-image.jpg" />

        {/* Start Elements Area  */}
        {/* <div className="rwt-counterup-area pb--100">
          <div className="container mt_dec--30">
            <CounterUpFour
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-4"
              textALign="text-center"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Corporate Team."
                  title="Corporate Team Member."
                  description=""
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        <FooterFour />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
