import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { useLocation } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

const HeaderTwo = ({
  btnStyle,
  HeaderSTyle,
  aboutRef,
  servicesRef,
  solutionRef,
  handleRef,
  homeRef,
}) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  console.log(servicesRef, "servicesRef");

  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/EchoServe-Logo_2.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/echoserve_logo_-_Copy__1_-removebg-preview.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav
                    aboutRef={aboutRef}
                    servicesRef={servicesRef}
                    solutionRef={solutionRef}
                    handleRef={handleRef}
                    homeRef={homeRef}
                  />
                </nav>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-8">
              <div className="header-right">
                <div className="header-btn">
                  <a className={`btn-default ${btnStyle}`} href="/contact">
                    Contact Us
                  </a>
                </div>
                <Darkmode />
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu
        show={ofcanvasShow}
        onClose={onCanvasHandler}
        aboutRef={aboutRef}
        servicesRef={servicesRef}
        solutionRef={solutionRef}
        handleRef={handleRef}
        homeRef={homeRef}
      />
    </>
  );
};
export default HeaderTwo;
