import React, { useState, useEffect } from 'react';

const Darkmode = () => {
  // Check if there is a saved theme preference in local storage
  const savedTheme = localStorage.getItem('theme');
  const [theme, setTheme] = useState(savedTheme || 'light');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    // Save the theme preference to local storage
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    // Apply the theme when the component mounts
    document.querySelector('body').classList.toggle('light', theme === 'light');
  }, [theme]);

  return (
    <button id="darkmode" onClick={switchTheme}>
      <img
        className="light-icon"
        src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`}
        alt="Sun images"
      />
      <img
        className="dark-icon"
        src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`}
        alt="Sun images"
      />
    </button>
  );
};

export default Darkmode;