import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.4758224571474!2d39.178717575270504!3d21.528242280249014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cfc4987296cf%3A0xe367779ff5b0caa7!2sEchoServe%20Jeddah!5e0!3m2!1sen!2ssa!4v1697110691124!5m2!1sen!2ssa"
          width="100%"
          height="50%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.747890924535!2d46.647142186243215!3d24.804084890713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee35d6a2f01fb%3A0xc84af90b443b3ccf!2sEchoServe%20Riyadh!5e0!3m2!1sen!2ssa!4v1697111056854!5m2!1sen!2ssa"
          width="100%"
          height="50%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
