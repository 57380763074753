import { FaShieldAlt, FaGlobe, FaClipboardList, FaCloud ,FaNetworkWired } from "react-icons/fa";

export const ServiceList = [
  {
    icon: <FaShieldAlt />,
    title: "Cyber Security Services",
    description:
      "Cyber security services provide comprehensive protection and defense against potential threats, vulnerabilities, and attacks.",
  },
  {
    icon: <FaGlobe />,
    title: "IT Infrastructure Services",
    description:
      "IT infrastructure services manage and optimize an organization's technology systems to enhance performance, security, and scalability.",
  },
  {
    icon: <FaClipboardList />,
    title: "Governance, Risk & Compliance (GRC) Services",
    description:
      "GRC services assist organizations in risk management, regulatory compliance, and governance practices.",
  },
];

export const SolutionsList = [
  {
    icon: <FaShieldAlt color="white" />,
    title: "Cyber Security",
    description: "There are many variations variations have.",
  },
  {
    icon: <FaCloud color="white" />,
    title: "Data Center & Cloud",
    description: "Passages there are many variations have.",
  },
  {
    icon: <FaNetworkWired  color="white" />,
    title: "Networking & Monitoring",
    description: "Variations There variations many Lorem Ipsum.",
  },
];
