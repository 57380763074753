import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_758ubdc",
        "template_1c2lv2h",
        e.target,
        "aETZFb0CSNwRdO5uV",
        { "g-recaptcha-response": 0.5 }
      )
      .then(
        (result) => {
          console.log(result.text, "result");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <input type="email" name="email" placeholder="Email Address" required />
      </div>

      <div className="form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
