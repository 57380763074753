import React from "react";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
const PortfolioBox = ({ portfolio }) => {
  return (
    <div className="rwt-card">
      <div className="box">
        <FiFacebook size={32}/>
      </div>
      <div className="contentBox">
        <h5 className="title mb--10">
          <Link
            to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`}
          >
            {portfolio.title}
          </Link>
        </h5>
        <span className="subtitle b2 text-capitalize">
          {portfolio.category}
        </span>
      </div>
    </div>
  );
};
export default PortfolioBox;
