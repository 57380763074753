import React from "react";
import SEO from "../common/SEO";
import FooterFour from "../common/footer/FooterFour";
import Copyright from "../common/footer/Copyright";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import HeaderTwo from "../common/header/HeaderTwo";
import { useEffect } from "react";
import ReactGA from "react-ga";

const HomeDefault = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <>
      <SEO title="Services" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    Our Services
                    <br />
                  </h1>
                  <p className="description">
                    Discover excellence in every service we offer – where
                    innovation meets quality, and your satisfaction is our
                    commitment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        {/* Start Portfolio Area  */}
        <div className="rwt-portfolio-area rn-section-gap-inside">
          <div className="container">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>
        {/* End Portfolio Area  */}

        <FooterFour />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
