import React from "react";
import Typed from "react-typed";
import BrandThree from "../brand/BrandThree";
import ScrollAnimation from "react-animate-on-scroll";
const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                Our Team
                <br />{" "}
                <Typed
                  className="theme-gradient"
                  strings={["Certificate.", "Skills.", "Accomplishments."]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p>
                At EchoServe, our team boasts a spectrum of industry-certified
                experts. From advanced technologies to cybersecurity, our
                professionals hold the credentials to ensure top-tier IT
                services. Choose a team that's not just experienced but
                certified for excellence.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <ScrollAnimation
              animateIn="bounce"
              initiallyVisible={true}
              animateOnce={true}
            >
              <BrandThree brandStyle="brand-style-2" />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
