import React from "react";
import { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FaRegCircle } from "react-icons/fa";
import Slider from "react-slick";

const SlpitOne = ({ img, list, images }) => {
  const [isMobile, setIsMobile] = useState(false);
  const settings = {
    //dots: true,
    infinite: true,
    slidesToShow: isMobile ? 2 : 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    arrows: false,
    draggable: false,
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src={img} alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              {/* <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Network and data security is a rapidly growing concern for
                  business across the globe. As cyber-security threats become
                  more advanced and frequent, it’s critical that companies
                  defend themselves against crippling attacks and invasive
                  breaches. EchoServe can work with your security team to
                  identify potential vulnerabilities
                </p>
              </ScrollAnimation> */}

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={false}
              >
                <ul className="split-list">
                  {list.map((item) => {
                    return (
                      <li>
                        <FaRegCircle
                          className="split-icon"
                          color="#345085"
                          size={15}
                        />
                        {item.point}
                      </li>
                    );
                  })}
                </ul>
              </ScrollAnimation>
            </div>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="slider-container">
                <Slider {...settings}>
                  {images?.map((item) => {
                    return <img src={item?.img} className="slider_img"></img>;
                  })}
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
