import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/aboutus1.jpg"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h2 className="title mt--10">About Our Business.</h2>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    EchoServe was established in Saudi Arabia in 2001,
                    specializing in the development and delivery of world-class
                    IT services and solutions for businesses and entities
                    throughout the Gulf region. Our cutting-edge services and
                    solutions, coupled with our talented team and exceptional
                    customer care and support, have led us to expand rapidly
                    across the Middle East. From our offices in Riyadh, and
                    Jeddah, we provide trusted IT services and solutions to some
                    of the largest and most esteemed companies and organizations
                    in the region. With a highly qualified team of IT and
                    business professionals who boast more than 75 years of
                    accumulated experience, EchoServe is transforming the way
                    its clients do business, through a winning combination of
                    unrivalled expertise, visionary foresight, and a tireless
                    commitment to excellence.
                  </p>
                </ScrollAnimation>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
