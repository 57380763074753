import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceFour = ({
  textAlign,
  serviceStyle,
  SolutionsList,
  callToAction,
}) => {
  return (
    <div className="row row--15 service-wrapper service-wrapper-box">
      {SolutionsList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="iconSolutions icon">
                <div className="line"></div>
                {val.icon}
              </div>
              <div className="content">
                <h4 className="title">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
            
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <div className="seeMoreButton">
          <Link className="btn-default text-underline" to="/solutions">
            <span>View More</span>
          </Link>
        </div>
      </ScrollAnimation>
    </div>
  );
};
export default ServiceFour;
