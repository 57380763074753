import React from "react";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import { FiX } from "react-icons/fi";

const MobileMenu = ({
  show,
  onClose,
  aboutRef,
  servicesRef,
  solutionRef,
  handleRef,
  homeRef,
}) => {
  var elements = document.querySelectorAll(
    ".popup-mobile-menu .has-droupdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-mobile-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var i in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[i].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            image={`${process.env.PUBLIC_URL}/images/logo/EchoServe-Logo_2.png`}
            image2={`${process.env.PUBLIC_URL}/images/logo/echoserve_logo_-_Copy__1_-removebg-preview.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}>
              <FiX />
            </span>
          </div>
        </div>
        <Nav
          aboutRef={aboutRef}
          servicesRef={servicesRef}
          solutionRef={solutionRef}
          handleRef={handleRef}
          homeRef={homeRef}
          onClose={onClose}
        />
      </div>
    </div>
  );
};
export default MobileMenu;
